import React, { useEffect } from "react";
import type { FC } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import { jssPreset, StylesProvider, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import GlobalStyles from "./components/GlobalStyles";
import ScrollReset from "./components/ScrollReset";
import GoogleAnalytics from "./components/GoogleAnalytics";
import routes, { renderRoutes } from "./routes";
import { withWidth } from "@material-ui/core";
import { setScreenSizeAction } from "./reducers/global";
import { useDispatch } from "./store";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import useSettings from "./hooks/useSettings";
import { initializeI18next } from "./plugins/i18next";
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC<{ width: string }> = ({ width }) => {
  const { settings } = useSettings();
  const dispatch = useDispatch();
  useEffect(() => {
    initializeI18next();
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    const setCustomVh = () => {
      document.documentElement.style.setProperty("--vh", window.innerHeight / 100 + "px");
    };
    window.addEventListener("resize", setCustomVh);
    return () => {
      window.removeEventListener("resize", setCustomVh);
    };
  }, []);
  const muiTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#5E1280",
      },
    },
    typography: {
      fontFamily: ["SUIT Variable"].join(","),
    },
  });

  // 스크린 사이즈 및 화면 방향 정보 저장
  useEffect(() => {
    dispatch(setScreenSizeAction(width, screen.orientation));
  }, [document.body.clientWidth]);

  return (
    <MuiThemeProvider theme={muiTheme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <Router history={history}>
              {/* <AuthProvider> */}
              <GlobalStyles />
              <ScrollReset />
              <GoogleAnalytics />
              {renderRoutes(routes)}
              {/* </AuthProvider> */}
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </MuiThemeProvider>
  );
};

export default withWidth()(App);

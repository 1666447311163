import { db, firestore } from "../../lib/firebase";
import { Notifier } from "./Notifier";

export class WebNotifier extends Notifier {
  notify(type) {
    db.collection(`User/${this.exhibitionData.owner}/Notification`)
      .add(this.getInsertData(type))
      .then(docRef => {
        docRef.update({ id: docRef.id });
      })
      .catch(error => {
        console.error("Error adding document: ", error);
      });
  }

  getInsertData(type) {
    return {
      type,
      title: this.createTitle(type),
      content: this.content,
      userId: this.exhibitionData.owner,
      isNotificationRead: false,
      createdAt: firestore.Timestamp.now(),
      data: {
        exhibitionId: this.exhibitionData.id,
        exhibitionTitle: this.exhibitionData.title,
        objectTitle: this.objectTitle,
        objectId: this.objectId,
        writer: this.writer,
      },
    };
  }

  createTitle(type): string {
    switch (type) {
      case Notifier.typeConst.LIKE: // 전시 좋아요
        return `전시 ${this.exhibitionData.title}에 좋아요가 눌러졌습니다.`;

      case Notifier.typeConst.OBJECTLIKE: // 작품 좋아요
        return `전시 ${this.exhibitionData.title}의 작품${this.objectTitle}에 좋아요가 눌러졌습니다.`;

      case Notifier.typeConst.COMMENT: // 전시 댓글
        return `전시 ${this.exhibitionData.title}에 ${this.writer}님이 댓글을 등록하였습니다.`;

      case Notifier.typeConst.OBJECTCOMMENT: // 작품 댓글
        return `전시 ${this.exhibitionData.title}의 작품 ${this.objectTitle}에 ${this.writer}님이 댓글을 등록하였습니다.`;

      case Notifier.typeConst.PAY:
      case Notifier.typeConst.EXPIRED:
        return "";
    }
  }
}

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};
// export const firebaseConfig = {
//   apiKey: "AIzaSyB47YBfOplGoAqc34bZHsx0QxFSvfbXjDU",
//   authDomain: "vrlecture.firebaseapp.com",
//   projectId: "vrlecture",
//   storageBucket: "vrlecture.appspot.com",
//   messagingSenderId: "641664447219",
//   appId: "1:641664447219:web:c6be73618f04edc8d9fd86",
//   measurementId: "G-QTCNZYVVLW",
// };
export const firebaseConfig = {
  apiKey: "AIzaSyA6DBt2yIqGp281DzIJbWqRT8PxEafY49M",
  authDomain: "vr-lecture.firebaseapp.com",
  projectId: "vr-lecture",
  storageBucket: "vr-lecture.appspot.com",
  messagingSenderId: "266898734056",
  appId: "1:266898734056:web:26250f611e23c3f4c1c25d",
  measurementId: "G-L5NKKY77C8",
};

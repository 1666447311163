import { Notifier } from "./Notifier";
import { EmailNotifier } from "./EmailNotifier";
import { KakaoNotifier } from "./KakaoNotifier";
import { WebNotifier } from "./WebNotifier";

export function sendNotifier(params) {
  switch (params.type) {
    case Notifier.typeConst.LIKE: // 전시 좋아요
      notifyWeb(params);
      break;

    case Notifier.typeConst.OBJECTLIKE: // 작품 좋아요
      notifyWeb(params);
      break;

    case Notifier.typeConst.COMMENT: // 전시 댓글
      notifyWeb(params);
      notifyEmail(params);
      notifyKakao(params);
      break;

    case Notifier.typeConst.OBJECTCOMMENT: // 작품 댓글
      notifyWeb(params);
      notifyEmail(params);
      notifyKakao(params);
      break;

    case Notifier.typeConst.PAY:
    case Notifier.typeConst.EXPIRED:
      break;
  }
}

async function notifyWeb(params) {
  const { type, exhibitionId, objectId, writer, content } = params;

  const notifier: WebNotifier = new WebNotifier();
  await notifier.init(exhibitionId, objectId, writer, content);
  notifier.notify(type);
}

async function notifyEmail(params) {
  const { type, exhibitionId, objectId, writer, content } = params;

  const notifier: EmailNotifier = new EmailNotifier();
  await notifier.init(exhibitionId, objectId, writer, content);
  notifier.notify(type);
}

async function notifyKakao(params) {
  const { type, exhibitionId, objectId, writer, content } = params;

  const notifier: KakaoNotifier = new KakaoNotifier();
  await notifier.init(exhibitionId, objectId, writer, content);
  notifier.notify(type);
}

import axios from "axios";
import { Notifier } from "./Notifier";

export class KakaoNotifier extends Notifier {
  async notify(type) {
    if (this.exhibitionData.expiredAt && new Date() <= this.exhibitionData.expiredAt.toDate()) {
      await this.getUserData();

      if (this.ownerData.agreeKakao) {
        this.createMessageData(type);
      }
    }
  }

  createMessageData(type) {
    let messageData;
    let templateCode;
    switch (type) {
      case Notifier.typeConst.LIKE: // 전시 좋아요
        break;

      case Notifier.typeConst.OBJECTLIKE: // 작품 좋아요
        messageData = {
          to: `82${this.ownerData.phoneNum.slice(1)}`,
          content: `[걸어본]\n좋아요 등록 안내\n\n${this.ownerData.userName}님, 전시 <${this.exhibitionData.title}>의 작품 ${this.objectTitle}에 좋아요가 눌러졌습니다.\n\n전시 보러가기: https://art.onthewall.io/${this.exhibitionData.id}\n\n해당 좋아요 등록 알림 메시지는 고객님의 알림 신청에 의해 발송됩니다.\n수신거부는 마이페이지 > 설정에서 변경할 수 있습니다.`,
        };
        templateCode = "like-alarm"; // 인포뱅크에서 설정 (변경x)

        this.sendKakaoMessage(messageData, templateCode);
        break;

      case Notifier.typeConst.COMMENT: // 전시 댓글
      case Notifier.typeConst.OBJECTCOMMENT: // 작품 댓글
        messageData = {
          to: `82${this.ownerData.phoneNum.slice(1)}`,
          content: `[걸어본]\n댓글 등록 안내\n\n${this.ownerData.userName}님, 전시 <${this.exhibitionData.title}>에 새로운 댓글이 등록되었습니다.\n\n전시 보러가기: https://art.onthewall.io/${this.exhibitionData.id}\n\n해당 댓글 등록 알림 메시지는 고객님의 알림 신청에 의해 발송됩니다.\n수신거부는 마이페이지 > 설정에서 변경할 수 있습니다.`,
        };
        templateCode = "comment-alarm"; // 인포뱅크에서 설정 (변경x)

        this.sendKakaoMessage(messageData, templateCode);
        break;

      case Notifier.typeConst.PAY:
        break;
      case Notifier.typeConst.EXPIRED:
        break;
    }
  }

  // 카카오 메세지 전송 요청
  sendKakaoMessage(messageData, templateCode) {
    axios({
      url: "https://us-central1-gd-virtual-staging.cloudfunctions.net/sendKakaoMessage",
      method: "post",
      data: {
        messageData,
        templateCode,
      },
    }).catch(err => {
      console.log(err);
    });
  }
}

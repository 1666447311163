import axios from "axios";
import { Notifier } from "./Notifier";

export class EmailNotifier extends Notifier {
  async notify(type) {
    if (this.exhibitionData.expiredAt && new Date() <= this.exhibitionData.expiredAt.toDate()) {
      await this.getUserData();

      if (this.ownerData.agreeEmail) {
        switch (type) {
          case Notifier.typeConst.LIKE: // 전시 좋아요
            break;

          case Notifier.typeConst.OBJECTLIKE: // 작품 좋아요
            this.sendEmail(`전시 ${this.exhibitionData.title}의 작품${this.objectTitle}에 좋아요가 눌러졌습니다.`);
            break;

          case Notifier.typeConst.COMMENT: // 전시 댓글
            this.sendEmailComment(`전시 ${this.exhibitionData.title}에 댓글이 등록되었습니다.`);
            break;

          case Notifier.typeConst.OBJECTCOMMENT: // 작품 댓글
            this.sendEmailComment(`작품 ${this.objectTitle}에 댓글이 등록되었습니다.`);
            break;

          case Notifier.typeConst.PAY:
          case Notifier.typeConst.EXPIRED:
            break;
        }
      }
    }
  }

  async sendEmail(title) {
    const userName = this.ownerData.userName;
    const exhibitionTitle = this.exhibitionData.title;
    const posterUrl = this.exhibitionData.postUrl;
    const toEmail = this.ownerData.userEmail;
    const paintingTitle = this.objectTitle;

    axios({
      url: "https://us-central1-gd-virtual-staging.cloudfunctions.net/sendEmailLike",
      method: "post",
      data: {
        userName,
        title,
        exhibitionTitle,
        posterUrl,
        toEmail,
        paintingTitle,
        exhibitionId: this.exhibitionData.id,
      },
    }).catch(err => {
      console.log(err);
      // 잠시 후 다시 시도
    });
  }

  async sendEmailComment(title) {
    const userName = this.ownerData.userName;
    const exhibitionTitle = this.exhibitionData.title;
    const posterUrl = this.exhibitionData.postUrl;
    const toEmail = this.ownerData.userEmail;
    const writer = this.writer;
    const paintingTitle = this.objectTitle;

    axios({
      url: "https://us-central1-gd-virtual-staging.cloudfunctions.net/sendEmailNewComment",
      method: "post",
      data: {
        userName,
        title,
        exhibitionTitle,
        posterUrl,
        toEmail,
        paintingTitle,
        writer,
      },
    }).catch(err => {
      console.log(err);
      // 잠시 후 다시 시도
    });
  }
}

import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen";
import ShowroomLayout from "./layouts/mainLayout/ShowroomLayout";
type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  // {
  //   exact: true,
  //   path: "/",
  //   component: lazy(() => import("./views/home")),
  // },
  // {
  //   exact: true,
  //   path: "/zoom",
  //   component: lazy(() => import("./views/Zoom")),
  // },
  {
    exact: true,
    path: "/guest/:id",
    component: lazy(() => import("./views/guest")),
    layout: ShowroomLayout,
  },
  {
    exact: true,
    path: "/host/:id",
    component: lazy(() => import("./views/host")),
    layout: ShowroomLayout,
  },
  {
    exact: true,
    path: "/self/:id",
    component: lazy(() => import("./views/selfStudy")),
  },
  {
    exact: true,
    path: "/preview/:id",
    component: lazy(() => import("./views/selfStudy")),
  },
  {
    exact: true,
    path: "/guestroom/:id",
    component: lazy(() => import("./views/guest_hive")),
    layout: ShowroomLayout,
  },
  {
    exact: true,
    path: "/hostroom/:id",
    component: lazy(() => import("./views/host_hive")),
    layout: ShowroomLayout,
  },
  {
    exact: true,
    path: "/selfStudy/:id",
    component: lazy(() => import("./views/selfStudy_hive")),
  },
  {
    exact: true,
    path: "/previewStudy/:id",
    component: lazy(() => import("./views/selfStudy_hive")),
  },
  // {
  //   exact: true,
  //   path: "/preview/:id",
  //   component: lazy(() => import("./views/preview")),
  // },

  {
    path: "*",
    routes: [
      {
        component: () => {
          <div>
            404 | 죄송합니다. 현재 찾을 수 없는 페이지를 요청 하셨습니다.{" "}
            <div>수업에 다시 접속하여 주시기 바랍니다.</div>
          </div>;
        },
      },
    ],
  },
];

export default routes;

import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  "@global": {
    html: {
      height: "calc(100 * var(--vh))",
      width: "100vw",
      overflow: "hidden",
    },
    body: {
      height: "calc(100 * var(--vh))",
      width: "100vw",
    },
  },
});
type Props = {
  children: React.ReactNode;
};

function ShowroomLayout({ children }: Props) {
  const classes = useStyles();
  return <>{children}</>;
}

export default ShowroomLayout;

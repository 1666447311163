import { Timestamp } from "@firebase/firestore-types";
import { db } from "../../lib/firebase";

type ExhibitionData = {
  id: string;
  title: string;
  postUrl: string;
  expiredAt: Timestamp;
  owner: string; // 주인 유저아이디
};

type ownerData = {
  agreeEmail: boolean;
  agreeKakao: boolean;
  userName: string;
  phoneNum: string;
  userEmail: string;
};

export abstract class Notifier {
  // 알림 종류
  static typeConst = {
    LIKE: "like",
    OBJECTLIKE: "objectLike",
    COMMENT: "comment",
    OBJECTCOMMENT: "objectComment",
    PAY: "pay",
    EXPIRED: "expired",
  };

  exhibitionData: ExhibitionData = {
    id: "",
    title: "",
    postUrl: "",
    expiredAt: null,
    owner: "",
  };

  ownerData: ownerData = {
    agreeEmail: false,
    agreeKakao: false,
    userName: "",
    phoneNum: "",
    userEmail: "",
  };

  objectId: string = "";
  objectTitle: string = "";

  writer: string = ""; // 댓글 작성자 이름
  content: string = ""; // 댓글 내용, 결제 금액 등

  // 전시 데이터 가져오기
  async init(exhibitionId: string, objectId: string, writer: string, content: string) {
    this.exhibitionData.id = exhibitionId;
    this.writer = writer;
    this.content = content;
    let exhibitionRef = db.collection("Exhibition").doc(exhibitionId);

    await exhibitionRef
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.exhibitionData.title = data.title;
          this.exhibitionData.postUrl = data.compressedPosterImage.url;
          this.exhibitionData.expiredAt = data.expiredAt;
          this.exhibitionData.owner = data.owner;
        } else {
          console.log("No such document!");
        }
      })
      .catch(error => {
        console.log("Error getting document:", error);
      });

    if (objectId) {
      this.objectId = objectId;
      let paintingRef = db.collection(`Exhibition/${exhibitionId}/Paintings`).doc(objectId);

      await paintingRef
        .get()
        .then(doc => {
          if (doc.exists) {
            this.objectTitle = doc.data().title;
          } else {
            console.log("No such document!");
          }
        })
        .catch(error => {
          console.log("Error getting document:", error);
        });
    }
  }

  // 유저데이터 가져오기
  async getUserData() {
    const userRef = db.collection("User").doc(this.exhibitionData.owner);
    await userRef
      .get()
      .then(doc => {
        if (doc.exists) {
          this.ownerData.agreeKakao = doc.data().agreeKakao;
          this.ownerData.agreeEmail = doc.data().agreeEmail;
          this.ownerData.userName = doc.data().userName;
          this.ownerData.phoneNum = doc.data().phone;
          this.ownerData.userEmail = doc.data().email;
        } else {
          console.log("No such document!");
        }
      })
      .catch(error => {
        console.log("Error getting document:", error);
      });
  }

  abstract notify(type): void;
}
